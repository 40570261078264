/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import useGraphqlClient from './useGraphqlClient';

export const CollectorFeedSearchQuery = gql`
  query CollectorFeedSearch(
    $where: token_holder_bool_exp,
  ) {
    token_holder_aggregate(
      where: $where,
    ) {
      aggregate {
        count
      }
    }
  }
`;

const useCollectionCount = (address) => {
  const gqlClient = useGraphqlClient();
  const query = useQuery(
    ['count', 'collector', address],
    async () => {
      const { token_holder_aggregate } = await gqlClient(
        CollectorFeedSearchQuery,
        {
          where: {
            holder_id: {
              _eq: address,
            },
            quantity: {
              _gt: 0,
            },
            token: {
              creator: {
                address: {
                  _neq: address,
                },
              },
              artifact_uri: {
                _neq: '',
              },
              supply: {
                _gt: 0,
              },
            },
          },
        },
      );
      return token_holder_aggregate?.aggregate?.count ?? 0;
    },
    {
      enabled: !!address,
    },
  );
  return {
    ...query,
    count: query.data,
    countLoading: query.status === 'loading',
  };
};

export default useCollectionCount;
