import Compressor from 'compressorjs';
import sumBy from 'lodash/sumBy';

/*
import { MIMETYPE } from '~/utils/mime';

export const getMimeType = (file: File) => new Promise((resolve) => {
  const filereader = new FileReader();
  filereader.onloadend = (e) => {
    if (e.target.readyState === FileReader.DONE) {
      // @ts-ignore
      const uint = new Uint8Array(e.target.result);
      const bytes = [];
      uint.forEach((byte) => {
        bytes.push(byte.toString(16));
      });
      const hex = bytes.join('').toUpperCase();

      let mimeType;

      switch (hex) {
        case '7BA2020':
          mimeType = MIMETYPE.GLTF;
          break;
        case '676C5446':
          mimeType = MIMETYPE.GLB;
          break;
        default:
          mimeType = 'Unknown MimeType';
      }

      resolve(mimeType);
    }
  };
  filereader.onerror = () => resolve('Unknown MimeType');
  filereader.readAsArrayBuffer(file.slice(0, 4));
});
*/

export type CompressedImage = {
  mimeType: string;
  buffer: ArrayBuffer;
  reader: string | ArrayBuffer;
};

export const generateCompressedImage = async (file: File, options): Promise<CompressedImage> => {
  const blob = await compressImage(file, options);
  return generateImage(blob);
};

export const generateImage = async (blob: File | Blob): Promise<CompressedImage> => {
  const mimeType = blob.type;
  const buffer = await blob.arrayBuffer();
  const reader = await blobToDataURL(blob);
  return { mimeType, buffer, reader };
};

export const coverOptions = {
  quality: 0.85,
  maxWidth: 1024,
  maxHeight: 1024,
};

export const thumbnailOptions = {
  quality: 0.85,
  maxWidth: 350,
  maxHeight: 350,
};

// eslint-disable-next-line no-async-promise-executor
export const compressImage = (file, options): Promise<Blob> => new Promise(async (resolve, reject) => {
  // eslint-disable-next-line no-new
  new Compressor(file, {
    ...options,
    success(blob) {
      resolve(blob);
    },
    error(err) {
      reject(err);
    },
  });
});

export const fileToBuffer = (file: File): Promise<Buffer> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    // @ts-ignore
    const buffer = Buffer.from(reader.result);
    resolve(buffer);
  };
  reader.onerror = reject;
  reader.readAsArrayBuffer(file);
});

export const blobToDataURL = async (blob: Blob): Promise<string | ArrayBuffer> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(blob);
});

export const formatBytes = (bytes: number, decimals: number = 0) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const fileToBlob = async (file) => {
  const buffer = await file.arrayBuffer();
  return new Blob([buffer], {
    type: file.type,
  });
};

export const getTotalSize = (files: File[] = []): number => sumBy(files, 'size');
