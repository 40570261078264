import styled from 'styled-components';
import Button, { buttonHeight } from './Button';

const ButtonSquare = styled(Button)`
  background-color: transparent;
  border-color: ${({ theme }) => theme.colors.borderXLight};
  width: ${buttonHeight}px;
  justify-content: center;
  padding: 0;
`;

export default ButtonSquare;
