/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import styled from 'styled-components';
import React from 'react';
import Link from 'next/link';
import Tippy from '@tippyjs/react';
import FollowButton from '~/components/FollowButton';
import TextTruncated from '~/components/TextTruncated';
import { ArtistPageProps } from '~/pageProps/artist';
import { User } from '~/types';
import useUserMetadataIPFS from '~/hooks/useUserMetadatIPFS';
import useUserWithDefault from '~/hooks/useUserWithDefault';
import UserAvatar from '~/components/UserAvatar';
import UserDescription from './UserDescription';
import UserLinks from './UserLinks';
import { getUserLink } from '~/graphql';
import FlexRow_ from '~/components/FlexRow';
import { CopiableAddress, ProfileText, ProfileTextTop, UserTzProfileBadge } from './UserProfileSection';
import { formatAmount, xtz } from '~/utils';
import useSearchObjkts, { useCreatorCount } from '~/hooks/useSearchObjkts';
import useCollectionCount from '~/hooks/useCollectionCount';
import useArtistVolume from '~/hooks/useArtistVolume';
import 'tippy.js/themes/material.css';
import TokenAsset from './TokenAsset';
import TokenLoader from './TokenLoader';

const FlexRow = styled(FlexRow_)`
  align-items: flex-start;
`;

const StyledUserLinks = styled(UserLinks)`
  flex-direction: column;
  margin-top: 10px;
`;

const StatsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1px;
  margin-top: 10px;  
`;

const UserPopoverData: React.FC<{
  user?: User,
} & ArtistPageProps> = ({
  user: userSsr = ({} as User),
}) => {
  const user = useUserWithDefault(userSsr.address, userSsr);
  const { data: volume, isLoading: isLoadingVolume } = useArtistVolume(user.address);
  const { data: metadata = {} } = useUserMetadataIPFS(userSsr.metadata_file);
  const creatorCount = useCreatorCount(user.address);
  const collectionCount = useCollectionCount(user.address);
  const {
    isLoading: isLoadingObjkts,
    // status,
    tokens: objkts = [],
    // fetchNextPage,
    // isFetchingNextPage,
  } = useSearchObjkts({
    creators: [user.address],
    limit: 3,
  });
  return (
    <>
      <div>
        <FlexRow style={ { marginBottom: 15 } }>
          <div>
            <Link href={ getUserLink(user) } passHref>
              <a>
                <UserAvatar user={ user } sizes={ { fallback: 60 } } />
              </a>
            </Link>
          </div>
          <div style={ { marginLeft: 'auto' } }>
            <FollowButton address={ user.address } />
          </div>
        </FlexRow>
        <ProfileText>
          <ProfileTextTop>
            <div style={ { flex: 1 } }>
              <FlexRow>
                <Link href={ getUserLink(user) } passHref>
                  <a>
                    <TextTruncated as="h2" style={ { marginTop: 0, marginBottom: 6 } }>
                      { user?.name || '-' }
                    </TextTruncated>
                  </a>
                </Link>
                <UserTzProfileBadge address={ user.address } />
              </FlexRow>
              <CopiableAddress address={ user.address } />
            </div>
          </ProfileTextTop>
          <UserDescription
            user={ user }
            metadata={ metadata }
          />
          <StyledUserLinks user={ user } />
          <StatsRow>
            <Link href={ `${getUserLink(user)}/collection` } passHref>
              <a>
                <div style={ { fontWeight: 'bold' } }>
                  { collectionCount.countLoading ? '-' : formatAmount(collectionCount.count) }
                </div>
                <div style={ { marginTop: -2 } }>
                  collected
                </div>
              </a>
            </Link>
            <Link href={ `${getUserLink(user)}/creations` } passHref>
              <a>
                <div style={ { fontWeight: 'bold' } }>
                  { creatorCount.countLoading ? '-' : formatAmount(creatorCount.count) }
                </div>
                <div style={ { marginTop: -2 } }>
                  created
                </div>
              </a>
            </Link>
            <Link href={ `${getUserLink(user)}/creations` } passHref>
              <a>
                <Tippy content="Total creations sales volume (for both primary and secondary sales)" theme="material">
                  <div>
                    <div style={ { fontWeight: 'bold' } }>
                      { isLoadingVolume ? '-' : `${formatAmount(volume, 0)} ${xtz}` }
                    </div>
                    <div style={ { marginTop: -2 } }>
                      total sales
                    </div>
                  </div>
                </Tippy>
              </a>
            </Link>
          </StatsRow>
          <StatsRow style={ { margin: -13, marginTop: 10 } }>
            {
              isLoadingObjkts ? Array.from({ length: 0 }).map((_, i) => i).map((o) => (
                <TokenLoader key={ `popover.objkt.loader.${o}` } />
              )) : objkts.map((objkt) => (
                <Link key={ `popover.objkt.${objkt.id}` } href={ `/o/${objkt.id}` } passHref>
                  <a>
                    <TokenAsset { ...objkt } />
                  </a>
                </Link>
              ))
            }
          </StatsRow>
        </ProfileText>
      </div>
    </>
  );
};

export default UserPopoverData;
