/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Objkt } from '~/types';
import {
  IMAGE_MIMETYPES,
  VIDEO_MIMETYPES,
  AUDIO_MIMETYPES,
  IPFS_DIRECTORY_MIMETYPE,
  // MIMETYPE,
  // INTERACTIVE_MIMETYPES,
  // OTHER_MIMETYPES,
} from '~/utils/mime';
import NftImage from './types/Image';
import NftVideo from './types/Video';
import NftAudio from './types/Audio';
import NftHtml from './types/Html';
import NftImageContainer from './components/NftImageContainer';
import { GATEWAY_IPFS, replaceIPFSCustom } from '~/utils';

export const BaseNftAsset: React.FC<Partial<Objkt>> = ({
  imageUrl,
}) => (
  <NftImageContainer>
    {
      imageUrl ? (
        <NftImage src={ imageUrl } />
      ) : null
    }
  </NftImageContainer>
);

export const NftAsset: React.FC<Objkt & { src?: string; }> = ({ src, ...objkt }) => {
  const {
    imageUrl,
    artifact_uri,
    mimeType,
  } = objkt;
  return (
    <>
      {
        IMAGE_MIMETYPES.includes(mimeType) ? (
          <NftImage src={ src || imageUrl } />
        ) : VIDEO_MIMETYPES.includes(mimeType) ? (
          <NftVideo src={ replaceIPFSCustom(artifact_uri, GATEWAY_IPFS) } poster={ imageUrl } $active />
        ) : AUDIO_MIMETYPES.includes(mimeType) ? (
          <NftAudio { ...objkt } />
        ) : [IPFS_DIRECTORY_MIMETYPE].includes(mimeType) ? (
          <NftHtml { ...objkt } />
        ) : (
          <BaseNftAsset { ...objkt } />
        )
      }
    </>
  );
};

/*
const NotSupported = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.textLight};
`;
*/

const TokenAsset: React.FC<Objkt & {
  className?: string;
}> = ({
  className,
  ...objkt
}) => (
  <NftImageContainer className={ className }>
    <NftAsset { ...objkt } />
  </NftImageContainer>
);

export default TokenAsset;
