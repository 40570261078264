import React from 'react';
import { BreakpointValues, useBreakpointValue } from '~/hooks/useBreakpoint';
import { User } from '~/types';
import useUserAvatar from '~/hooks/useUserAvatar';
import AvatarWithModal from './AvatarWithModal';
import Avatar, { AvatarContainer } from './Avatar';

const UserAvatar: React.FC<{
  user: User;
  sizes?: BreakpointValues;
  withModal?: boolean;
  className?: string;
}> = ({
  user = {} as User,
  sizes,
  withModal,
  className,
}) => {
  const avatar = useUserAvatar(user);
  const avatarSize = useBreakpointValue(sizes);
  return !avatar ? (
    <AvatarContainer $size={ avatarSize } className={ className } />
  ) : withModal ? (
    <AvatarWithModal src={ avatar } sizes={ sizes } className={ className } />
  ) : (
    <Avatar src={ avatar } size={ avatarSize } className={ className } />
  );
};

export default UserAvatar;
