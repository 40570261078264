import { User } from '~/types';
import useUser from './useUser';

const useUserWithDefault = (address: string, defaultUser: User): User => {
  const { data: user } = useUser(address);
  return {
    ...defaultUser,
    ...user,
  };
};

export default useUserWithDefault;
