/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import styled from 'styled-components';
import { useRouter } from 'next/router';
import React from 'react';
import { shortenAddress } from '~/utils';
import FollowButton from '~/components/FollowButton';
import useAddress from '~/hooks/useAddress';
import { Breakpoints, media } from '~/styles/media';
import useBreakpoint from '~/hooks/useBreakpoint';
import TextTruncated from '~/components/TextTruncated';
import { ArtistPageProps } from '~/pageProps/artist';
import { User, UserPageQuery } from '~/types';
import TzProfileBadge from '~/components/TzProfileBadge';
import useUserMetadataIPFS from '~/hooks/useUserMetadatIPFS';
import useUserWithDefault from '~/hooks/useUserWithDefault';
import ExternalLink from '~/components/ExternalLink';
import CopyAddressButton from '~/components/CopyAddressButton';
import FlexRow_ from '~/components/FlexRow';
import UserAvatar from '~/components/UserAvatar';
import UserActionsButton from './UserActionsButton';
import ShareButton from './ShareButton';
import UserDescription from './UserDescription';
import UserLinks from './UserLinks';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import Button from './Button';

const StyledUserLinks = styled(UserLinks)`
  margin-top: 5px;
`;

const FlexRow = styled(FlexRow_)`
  align-items: flex-start;
`;

const ProfileSectionContainer = styled(FlexRow)`
  margin-bottom: 15px;
  min-height: 112px;
`;

export const ProfileText = styled.div`
  flex: 1;
  white-space: normal;
`;

export const ProfileTextTop = styled(FlexRow)`
  margin-bottom: 10px;
  ${media.mobile`
    display: block;
  `}
`;

export const UserTzProfileBadge = styled(TzProfileBadge)`
  font-size: 1.2rem;
  position: relative;
  left: 6px;
  top: 0px;
`;

const ButtonsRow = styled.div`
  display: flex;
  width: fit-content;
  > * {
    margin-left: 10px;
  }
  ${media.mobile`
    margin-top: 10px;
    flex-direction: row;
  `}
`;

export const useUserRedirect = (userInit) => {
  const router = useRouter();
  const address = useAddress();
  const { name, section, subsection } = router.query as UserPageQuery;
  const user = useUserWithDefault(address, userInit);
  React.useEffect(() => {
    if (user.name && !name) {
      const url = `/${[
        encodeURIComponent(user.name),
        section,
        subsection,
      ].filter(Boolean).join('/')}`;
      router.replace(url, url, { shallow: true });
    }
  }, [user.name, name, address, section, subsection, router]);
};

export const CopiableAddress = ({ address }) => {
  const tzktUrl = `https://tzkt.io/${address}/operations/`;
  return (
    <FlexRow>
      <ExternalLink href={ tzktUrl }>
        { shortenAddress(address) }
      </ExternalLink>
      <CopyAddressButton address={ address } />
    </FlexRow>

  );
};

const StyledExternalLink = styled(ExternalLink)`
  display: inline;
  &:hover {
    text-decoration: none;
  }
`;

const EditProfileButton = () => (
  <StyledExternalLink href="https://hen-mirror.hic.af/config">
    <Button>Edit profile</Button>
  </StyledExternalLink>
);

const UserProfileSection: React.FC<{
  user?: User,
  isLoading?: boolean;
} & ArtistPageProps> = ({
  isLoading,
  user: userSsr = ({} as User),
}) => {
  const { acc } = useHicetnuncContext();
  const isSelfProfile = acc?.address === userSsr.address;
  const router = useRouter();
  const address = useAddress();
  const user = useUserWithDefault(address, userSsr);
  const breakpoint = useBreakpoint();
  const { data: metadata = {} } = useUserMetadataIPFS(userSsr.metadata_file);
  const path = router.asPath?.split('?')?.[0];
  const shareUrl = `https://hic.af${path}`;
  return (
    <>
      <ProfileSectionContainer>
        <div style={ { marginRight: 15 } }>
          <UserAvatar user={ user } withModal />
        </div>
        <ProfileText>
          {
            isLoading ? null : (
              <>
                <ProfileTextTop>
                  <div style={ { flex: 1 } }>
                    <FlexRow>
                      <TextTruncated as="h1" style={ { marginTop: 0, marginBottom: 6 } }>
                        { user?.name || '-' }
                      </TextTruncated>
                      <UserTzProfileBadge address={ user.address } />
                    </FlexRow>
                    <CopiableAddress address={ user.address } />
                  </div>
                  <ButtonsRow>
                    <UserActionsButton address={ user.address } />
                    <ShareButton url={ shareUrl } />
                    {
                      isSelfProfile ? (
                        <EditProfileButton />
                      ) : (
                        <FollowButton address={ user.address } />
                      )
                    }
                  </ButtonsRow>
                </ProfileTextTop>
                {
                  breakpoint === Breakpoints.fallback ? (
                    <>
                      <UserDescription
                        user={ user }
                        metadata={ metadata }
                      />
                      <StyledUserLinks user={ user } />
                    </>
                  ) : null
                }
              </>
            )
          }
        </ProfileText>
      </ProfileSectionContainer>
      {
        [Breakpoints.mobile, Breakpoints.tablet].includes(breakpoint) ? (
          <>
            <UserDescription user={ user } metadata={ metadata } />
            <StyledUserLinks user={ user } />
          </>
        ) : null
      }
    </>
  );
};

export default UserProfileSection;
