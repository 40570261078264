import { useDataContext } from '~/contexts/Data';
import Button from './Button';

const FollowButton: React.FC<{
  address: string;
  className?: string;
}> = ({
  address,
  className,
}) => {
  const { followed, setFollowed } = useDataContext();
  const isFollowed = followed?.includes(address);
  // @ts-ignore
  const follow = (addy: string) => setFollowed((f = []) => [...f, addy]);
  // @ts-ignore
  const unfollow = (addy: string) => setFollowed((f = []) => f.filter((a) => a !== addy));
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isFollowed) {
      unfollow(address);
    } else {
      follow(address);
    }
  };
  return isFollowed ? (
    <Button $primary onClick={ handleClick } className={ className }>Followed</Button>
  ) : (
    <Button onClick={ handleClick } className={ className }>Follow</Button>
  );
};

export default FollowButton;
