/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useQuery } from 'react-query';
import request, { gql } from 'graphql-request';

const UserClaimsQuery = gql`
  query UserClaimsQuery($address: String!) {
    tzprofiles_by_pk(
      account: $address
    ) {
      valid_claims
    }
  }
`;

type TzProfile = {
  twitter?: string;
  discord?: string;
  github?: string;
  alias?: string;
};

const parseUserMetadata = (tzprofiles_by_pk: any, address: string): TzProfile => {
  const { valid_claims = [] } = tzprofiles_by_pk || {};
  const tzktData = {} as any;
  const tzpData = {} as any;
  for (const claim of valid_claims) {
    const claimJSON = JSON.parse(claim[1]);
    if (claimJSON.type.includes('TwitterVerification')) {
      if (!tzktData.data || !tzktData.data.twitter) {
        tzpData.twitter = claimJSON.evidence.handle;
      }
    } else if (claimJSON.type.includes('GitHubVerification')) {
      if (!tzktData.data) {
        tzpData.github = claimJSON.evidence.handle;
      }
    } else if (claimJSON.type.includes('DiscordVerification')) {
      if (!tzktData.data) {
        tzpData.discord = claimJSON.evidence.handle;
      }
    } else if (claimJSON.type.includes('BasicProfile')) {
      if (claimJSON.credentialSubject.alias !== ''
        && !(tzktData.data && tzktData.data.alias)) {
        tzpData.alias = claimJSON.credentialSubject.alias;
      }
      tzpData.tzprofile = address;
    }
  }
  if (tzpData) {
    tzktData.data = tzpData;
  }
  return tzktData.data;
};

const useTzProfile = (address: string) => useQuery<TzProfile>(
  ['user', 'metadata', address],
  async () => {
    const { tzprofiles_by_pk } = await request(
      'https://indexer.tzprofiles.com/v1/graphql',
      UserClaimsQuery,
      {
        address,
      },
    );
    return parseUserMetadata(tzprofiles_by_pk, address);
  },
  {
    enabled: !!address,
  },
);

export default useTzProfile;

export const useHasTzProfile = (address: string) => {
  const { data: tzProfile = {} } = useTzProfile(address);
  const hasTzProfile = Object.keys(tzProfile).length > 0;
  return hasTzProfile;
};
