import { RiFullscreenFill } from 'react-icons/ri';
import styled from 'styled-components';
import React from 'react';
import { Objkt } from '~/types';
import { openFullscreen } from '~/utils';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';

const FullScreenButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  color: white;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 200ms ease;
  background-color: ${({ theme }) => theme.colors.background};
  &:hover {
    background-color: ${({ theme }) => theme.colors.foreground};
  }
`;

const Iframe = styled.iframe<{
  $active?: boolean;
  $hidden?: boolean;
}>`
  width: 100%;
  height: 100%;
  outline: 0;
  border-style: none;
  background-color: black;
  &:active,
  &:focus {
    outline: 0;
  }
  ${({ $hidden }) => $hidden && `
    display: none;
  `}
`;

const FullScreenInteractiveObjktButton: React.FC<{
  objkt: Objkt;
  className?: string;
}> = ({
  objkt,
  className,
}) => {
  const iframeRef = React.useRef<HTMLIFrameElement>();
  const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);
  const { acc } = useHicetnuncContext();
  const {
    id,
    assetUrl,
    creator,
  } = objkt;
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openFullscreen(iframeRef.current);
  };
  React.useEffect(() => {
    iframeRef.current?.addEventListener('fullscreenchange', () => {
      setIsFullscreen(!!document.fullscreenElement);
    });
  }, []);
  return (
    <>
      <Iframe
        ref={ iframeRef }
        src={ `${assetUrl}/?creator=${creator?.address}&viewer=${acc?.address}&objkt=${id}` }
        sandbox="allow-scripts allow-same-origin"
        allow="accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;"
        $hidden={ !isFullscreen }
      />
      <FullScreenButton
        className={ className }
        onClick={ handleClick }
      >
        <RiFullscreenFill size={ 20 } />
      </FullScreenButton>
    </>
  );
};

export default FullScreenInteractiveObjktButton;
