import React from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { BsPause, BsPlay } from 'react-icons/bs';
import { useAudioPlayer } from '~/contexts/AudioPlayer';
import { Objkt } from '~/types';
import NftImage from './Image';
import useIsMobile from '~/hooks/useIsMobile';
import LoadIcon from '~/components/LoadIcon';

const NftAudioImage = styled(NftImage)`
  width: 100%;
`;

const playButtonVariant = {
  initial: { opacity: 0, duration: 50 },
  isOpen: { opacity: 1, duration: 50 },
  exit: { opacity: 0, duration: 50 },
};

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayButton = styled(motion.div)<{ $size?: number }>`
  width: ${({ $size = 50 }) => $size}px;
  height: ${({ $size = 50 }) => $size}px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const NftAudioPlayButton: React.FC<Objkt> = (objkt) => {
  const isMobile = useIsMobile();
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const { load, play, pause, isPlaying, isLoading, objkt: objktPlaying } = useAudioPlayer();
  const isSelf = objktPlaying?.id === objkt?.id;
  const isPlayingSelf = isSelf && isPlaying;
  const isLoadingSelf = isSelf && isLoading;
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPlayingSelf) {
      pause();
    } else {
      play(objkt);
    }
  };
  const handleHover = () => {
    load(objkt);
  };
  return (
    <PlayButtonWrapper
      onMouseEnter={ () => setIsHovered(true) }
      onMouseLeave={ () => setIsHovered(false) }
    >
      <AnimatePresence>
        {
          isMobile || isHovered || isSelf ? (
            <PlayButton
              key="playButton"
              initial="initial"
              animate="isOpen"
              exit="exit"
              variants={ playButtonVariant }
              onClick={ handleClick }
              onMouseEnter={ handleHover }
            >
              {
                isLoadingSelf ? (
                  <LoadIcon $animating />
                ) : isPlayingSelf ? (
                  <BsPause size={ 28 } />
                ) : (
                  <BsPlay size={ 28 } />
                )
              }
            </PlayButton>
          ) : null
        }
      </AnimatePresence>
    </PlayButtonWrapper>
  );
};

const NftAudio: React.FC<Objkt> = (objkt) => {
  const {
    imageUrl,
  } = objkt;
  return (
    <>
      <NftAudioImage src={ imageUrl } alt="audio" />
      <NftAudioPlayButton { ...objkt } />
    </>
  );
};

export default NftAudio;
