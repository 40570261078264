import { User } from '~/types';
import useUsers, { getUsersData } from './useUsers';

export const getUserData = async (
  address: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any,
): Promise<User> => getUsersData(
  [address],
  client,
).then(([user] = []) => user);

const useUser = (address: string) => {
  const query = useUsers([address]);
  return {
    ...query,
    data: query.data?.[0],
  };
};

export default useUser;
