import { HiOutlineBadgeCheck } from 'react-icons/hi';
import styled from 'styled-components';
import React from 'react';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import Modal, { ModalHeader, ModalCloseButton, ModalBody } from '~/components/Modal';
import ExternalLink from './ExternalLink';
import useTzProfile from '~/hooks/useTzProfile';
import { colors } from '~/styles/themes';

const TzProfileBadgeIcon = styled(HiOutlineBadgeCheck)<{ $size?: number }>`
  font-size: ${({ $size }) => $size}px;
  cursor: pointer;
  transition: color 200ms ease;
  color: ${({ theme }) => theme.colors.text};
  &:hover {
    color: ${({ theme }) => theme.colors.textLight};
  }
`;

const SocialIcon = styled.div`
    margin-right: 6px;
    position: relative;
    top: 2px;
`;

const TzProfileBadge: React.FC<{
  address: string;
  className?: string;
  size?: number;
}> = ({
  address,
  className,
  size,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { data: tzProfile = {} } = useTzProfile(address);
  const hasTzProfile = Object.keys(tzProfile).length > 0;
  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };
  const handleClose = () => setIsOpen(false);
  return hasTzProfile ? (
    <>
      <TzProfileBadgeIcon onClick={ handleOpen } $size={ size } className={ className } />
      <Modal { ...{ isOpen, handleClose } }>
        <ModalHeader>
          <h2>
            <TzProfileBadgeIcon style={
              {
                position: 'relative',
                marginRight: 5,
                top: 2,
              }
            }
            />
            VERIFIED TEZOS PROFILE
          </h2>
          <ModalCloseButton onClick={ handleClose } />
        </ModalHeader>
        <ModalBody style={ { padding: 20 } }>
          {
            `This wallet has a verified Tezos Profile. Whilst it gives some more confidence, it doesn't mean this account is 100% legit.
            Please review the history of this account to make sure you don't collect a copymint.`
          }
          <div style={ { marginTop: 10 } }>
            {
              tzProfile.discord ? (
                <div>
                  <SocialIcon as={ FaDiscord } style={ { color: colors.discord } } />
                  <span>{ `${tzProfile.discord} on Discord`}</span>
                </div>
              ) : null
            }
            {
              tzProfile.twitter ? (
                <ExternalLink href={ `https://twitter.com/${tzProfile.twitter}` }>
                  <SocialIcon as={ FaTwitter } style={ { color: colors.twitter } } />
                  <span>{ `${tzProfile.twitter} on Twitter`}</span>
                </ExternalLink>
              ) : null
            }
          </div>
          <ExternalLink
            href={ `https://tzprofiles.com/view/mainnet/${address}` }
            style={ { marginTop: 10, fontWeight: 'bold' } }
          >
            View this wallet on tzprofiles.com
          </ExternalLink>
        </ModalBody>
      </Modal>
    </>
  ) : null;
};

export default TzProfileBadge;
