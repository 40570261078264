import React from 'react';
import styled from 'styled-components';
import TextLimitable from '~/components/TextLimitable';

const Container = styled.div`
  margin-top: 0;
  word-wrap: break-word;
`;

const UserDescription = ({
  user,
  metadata,
}) => (
  <Container>
    <TextLimitable text={ metadata?.description || user?.metadata?.description } limit={ 100 } />
  </Container>
);

export default UserDescription;
