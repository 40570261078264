/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-request';
import { sumBy } from 'lodash';
import { useQuery } from 'react-query';
import { Trade } from '~/types';
import { DIVIDER } from '~/utils/const';
import useGraphqlClient from './useGraphqlClient';

const ArtistVolumeQuery = gql`
    query ArtistVolume($address: String) {
        trade(
            where: {
                token: {
                    creator_id: {
                        _eq: $address
                    }
                }
            }
        ) {
            amount
            swap {
                price
            }
        }
    }
`;

const useArtistVolume = (address: string) => {
  const gqlClient = useGraphqlClient();
  return useQuery<number>(
    ['artist.volume', address],
    async () => {
      const { trade: trades = [] } = await gqlClient<{ trade: Trade[] }>(
        ArtistVolumeQuery,
        { address },
      );
      const volume = sumBy((trades as Trade[]), (trade) => trade.swap.price * trade.amount || 0) / DIVIDER;
      return volume;
    },
    { enabled: !!address },
  );
};

export default useArtistVolume;
