/* eslint-disable @typescript-eslint/naming-convention */
import { isEmpty } from 'lodash';
import { User } from '~/types';
import useUserMetadataIPFS from './useUserMetadatIPFS';

const useUserAvatar = (user: User): string => {
  const {
    address,
    metadata,
    metadata_file,
  } = user;
  const { data: metadataIPFS = {} } = useUserMetadataIPFS(metadata_file);
  const defaultAvatar = !isEmpty(user) && `https://services.tzkt.io/v1/avatars/${address}`;
  const avatar = metadataIPFS?.identicon || metadata?.identicon || defaultAvatar;
  return avatar;
};

export default useUserAvatar;
