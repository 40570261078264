/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import parseISO from 'date-fns/parseISO';
import { calendar } from '~/hooks/useTimeAgo';
import useGraphqlClient from './useGraphqlClient';

const FirstMintQuery = gql`
    query FirstMintQuery($address: String) {
        token(
            where: {
                creator_id: {
                    _eq: $address
                }
            },
            order_by: {
                timestamp: asc
            },
            limit: 1
        ) {
            id
            timestamp
            title
        }
    }

`;

const useFirstMint = (address: string) => {
  const gqlClient = useGraphqlClient();
  return useQuery(
    ['first.mint', address],
    async () => {
      const { token } = await gqlClient(
        FirstMintQuery,
        { address },
      );
      return token?.[0];
    },
    { enabled: !!address },
  );
};

export default useFirstMint;

export const useActiveSince = (address: string) => {
  const query = useFirstMint(address);
  const firstMint = query.data;
  const activeSince = firstMint?.timestamp && calendar(parseISO(firstMint?.timestamp));
  return {
    ...query,
    firstMint,
    activeSince,
  };
};
