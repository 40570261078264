import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { userFragment } from '~/graphql';
import { User } from '~/types';
import useGraphqlClient from '~/hooks/useGraphqlClient';

const UsersQuery = gql`
    query Users($addresses: [String!]!) {
        holder(where: { address: {_in: $addresses}}) {
          ${userFragment}
        }
    }
`;

export const getUsersData = async (
  addresses: string[] = [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any,
): Promise<User[]> => {
  if (!addresses) return null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { holder = [] } = await client(
    UsersQuery,
    { addresses: addresses.filter(Boolean) },
  );
  return holder;
};

const useUsers = (addresses: string[]) => {
  const gqlClient = useGraphqlClient();
  return useQuery<User[]>(
    ['users', ...addresses],
    () => getUsersData(addresses.filter(Boolean), gqlClient),
    {
      enabled: addresses.length > 0,
    },
  );
};

export default useUsers;
