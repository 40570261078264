import Tippy from '@tippyjs/react';
import React from 'react';
import { User } from '~/types';
import { DropdownContainer2, DropdownContent } from './Dropdown';
import UserPopoverData from './UserPopoverData';

const Drop: React.FC<{
  user: User;
  shouldRender: boolean;
}> = ({
  user,
  shouldRender,
}) => (
  <DropdownContainer2>
    {
      shouldRender ? (
        <DropdownContent>
          <UserPopoverData user={ user } />
        </DropdownContent>
      ) : null
    }
  </DropdownContainer2>
);

const UserPopover = ({ user, children }) => {
  const [shouldRender, setShouldRender] = React.useState<boolean>(false);
  return (
    <Tippy
      placement="top"
      delay={ 600 }
      interactive
      interactiveBorder={ 20 }
      onShow={ () => setShouldRender(true) }
      content={ <Drop user={ user } shouldRender={ shouldRender } /> }
    >
      {children}
    </Tippy>
  );
};

export default UserPopover;
