import axios from 'axios';
import { useQuery } from 'react-query';
import { replaceIPFS } from '~/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UserMetadata = any;

export const getUserMetadataIPFS = (metadata_file): Promise<UserMetadata> => metadata_file
  && axios.get(replaceIPFS(metadata_file)).then(({ data }) => data);

const useUserMetadataIPFS = (metadata_file: string) => useQuery<UserMetadata>(
  ['metadata', metadata_file],
  () => getUserMetadataIPFS(metadata_file),
  { enabled: !!metadata_file },
);

export default useUserMetadataIPFS;
