/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import { Objkt } from '~/types';
import RatioBox from '~/components/RatioBox';
import { FixedModalButtonStyle } from '~/components/Modal';
import FullScreenInteractiveObjktButton from '~/components/FullscreenInteractiveObjktButton';
import NftImage from './Image';
import { dataRUIToBuffer, prepareFilesFromZIP, validateFiles } from '~/utils/html';

const uid = Math.round(Math.random() * 100000000).toString();

const FullscreenButtonModal = styled(FullScreenInteractiveObjktButton)`
  ${FixedModalButtonStyle};
  top: auto;
  bottom: 0;
`;

const LoadingIframe = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Iframe = styled.iframe<{
  $active?: boolean;
  $hidden?: boolean;
}>`
  width: 100%;
  height: 100%;
  outline: 0;
  border-style: none;
  background-color: black;
  &:active,
  &:focus {
    outline: 0;
  }
  ${({ $hidden }) => $hidden && `
    display: none;
  `}  
`;

const NftHtml: React.FC<Objkt> = (objkt) => {
  const {
    id,
    assetUrl,
    creator,
    $active,
    preview_uri,
    imageUrl,
  } = objkt;
  const { address } = useHicetnuncContext();

  let _creator_ = '';
  let _viewer_ = '';
  let _objectId_ = '';

  if (creator && creator.address) {
    _creator_ = creator.address;
  }

  if (address) {
    _viewer_ = address;
  }

  if (id) {
    _objectId_ = String(id);
  }

  const [iframeLoaded, setIframeLoaded] = React.useState<boolean>(false);
  // preview
  const iframeRef = React.useRef(null);
  const unpackedFiles = React.useRef(null);
  const unpacking = React.useRef(false);
  const [validHTML, setValidHTML] = React.useState(null);
  const [validationError, setValidationError] = React.useState(null);

  const unpackZipFiles = async () => {
    unpacking.current = true;

    const buffer = dataRUIToBuffer(preview_uri);
    const filesArr = await prepareFilesFromZIP(buffer);
    const files = {};
    filesArr.forEach((f) => {
      files[f.path] = f.blob;
    });

    unpackedFiles.current = files;

    const result = await validateFiles(unpackedFiles.current);
    if (result.error) {
      console.error(result.error);
      setValidationError(result.error);
    } else {
      setValidationError(null);
    }
    setValidHTML(result.valid);

    unpacking.current = false;
  };

  if (preview_uri && !$active && !unpackedFiles.current && !unpacking.current) {
    unpackZipFiles();
  }

  React.useEffect(() => {
    const handler = async (event) => {
      if (event.data !== uid) {
        return;
      }

      iframeRef.current.contentWindow.postMessage(
        {
          target: 'hicetnunc-html-preview',
          data: unpackedFiles.current,
        },
        '*',
      );
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [preview_uri]);

  if (!$active) {
    // creator is viewer in preview
    // _creator_ = _viewer_;

    if (validHTML) {
      return (
        <iframe
          ref={ iframeRef }
          title="html-zip-embed"
          src={ `https://hicetnunc2000.github.io/hicetnunc/gh-pages/html-preview/?uid=${uid}&creator=${_creator_}&viewer=${_viewer_}&objkt=${_objectId_}` }
          sandbox="allow-scripts allow-same-origin allow-modals"
          allow="accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;"
          onLoad={ () => setIframeLoaded(true) }
        />
      );
    } if (validHTML === false) {
      return (
        <div>
          Preview Error:
          {validationError}
        </div>
      );
    }
  }
  if (!$active) {
    return (
      <>
        {/*
        <Iframe
          ref={ iframeRef }
          title="html-embed"
          src={ `${assetUrl}/?creator=${_creator_}&viewer=${_viewer_}&objkt=${_objectId_}` }
          sandbox="allow-scripts allow-same-origin"
          allow="accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;"
          $active={ $active }
          onLoad={ () => setIframeLoaded(true) }
        />
        */}
        <NftImage src={ imageUrl } />
        <FullScreenInteractiveObjktButton objkt={ objkt } />
      </>
    );
  }
  return (
    <>
      {
        iframeLoaded ? null : (
          <LoadingIframe>Loading...</LoadingIframe>
        )
      }
      <RatioBox>
        <Iframe
          ref={ iframeRef }
          title="html-embed"
          src={ `${assetUrl}/?creator=${_creator_}&viewer=${_viewer_}&objkt=${_objectId_}` }
          sandbox="allow-scripts allow-same-origin"
          allow="accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;"
          $active={ $active }
          onLoad={ () => setIframeLoaded(true) }
        />
        <FullscreenButtonModal objkt={ objkt } />
      </RatioBox>
    </>
  );
};

export default NftHtml;
