import { gql } from 'graphql-request';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { userFragment } from '~/graphql';
import useGraphqlClient from './useGraphqlClient';

const UserQuery = gql`
    query subjktsQuery($subjkt: String!) {
        holder(where: { name: {_eq: $subjkt}}) {
            ${userFragment}
        }
    }
`;

type PageQuery = {
  name: string;
  address: string,
  section: 'collection';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAddressFromName = async (name: string, client: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { holder = [] } = await client(
    UserQuery,
    { subjkt: name },
  );
  return holder[0]?.address;
};

const useAddress = () => {
  const router = useRouter();
  const { name, address } = router.query as PageQuery;
  const gqlClient = useGraphqlClient();
  const { data: realAddress } = useQuery(
    ['username', name],
    () => getAddressFromName(name, gqlClient),
    { enabled: !!name && !address },
  );
  return realAddress || address;
};

export default useAddress;
