import styled from 'styled-components';
import React from 'react';
import Avatar, { AvatarProps } from '~/components/Avatar';
import { BreakpointValues, useBreakpointValue } from '~/hooks/useBreakpoint';
import Modal from './Modal';

export const StyledAvatar = styled(Avatar)<AvatarProps>`
  cursor: pointer;
`;

const ModalAvatar = styled(Avatar)`
  width: 100%;
  height: auto;
  border-radius: 2px;
  border: 0;
`;

const AvatarWithModal: React.FC<{
  src: string;
  sizes?: BreakpointValues;
  className?: string;
}> = ({
  src,
  sizes,
  className,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const avatarSize = useBreakpointValue(sizes);
  return (
    <>
      <StyledAvatar
        src={ src }
        onClick={ () => (src ? setIsOpen(true) : null) }
        size={ avatarSize }
        className={ className }
      />
      <Modal
        isOpen={ isOpen }
        handleClose={ () => setIsOpen(false) }
        backdropStyle={
          {
            backgroundColor: 'black',
            padding: 0,
          }
        }
        windowStyle={
          {
            borderWidth: 0,
          }
        }
      >
        <div style={ { padding: 15 } }>
          <ModalAvatar src={ src } onClick={ () => setIsOpen(false) } />
        </div>
      </Modal>
    </>
  );
};

export default AvatarWithModal;
