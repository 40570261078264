import styled from 'styled-components';
import { RiFileCopyLine, RiCheckLine } from 'react-icons/ri';
import React from 'react';
import { colors } from '~/styles/themes';
import useCopyToClipboard from '~/hooks/useCopyToClipboard';

const ButtonContainer = styled.button.attrs({
  type: 'button',
})`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 8px;
    transform: translateY(2px);
    color: ${({ theme }) => theme.colors.textLight};
    transition: color 200ms ease;
    &:hover {
        color: ${({ theme }) => theme.colors.text};
    }
`;

const CopyAddressButton: React.FC<{ address: string }> = ({ address }) => {
  const { justCopied, handleCopy } = useCopyToClipboard();
  return (
    <ButtonContainer onClick={ () => handleCopy(address) }>
      {
        justCopied ? (
          <RiCheckLine color={ colors.success } />
        ) : (
          <RiFileCopyLine />
        )
      }
    </ButtonContainer>
  );
};

export default CopyAddressButton;
