/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { useCallbackRef } from 'use-callback-ref';
// import NftImage from './Image';

const NftVideoInner = styled.video.attrs({
  autoPlay: true,
  playsInline: true,
  loop: true,
  muted: true,
  controlsList: 'nodownload',
  crossorigin: 'anonymous',
})`
  width: 100%;
  height: 100%;
`;

const NftVideo = (props) => {
  const { src, poster, $active } = props;
  const videoRef = useCallbackRef<HTMLVideoElement>(null, (ref) => {
    // @ts-ignore
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; }(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)));
    if (!isSafari && ref) {
      // eslint-disable-next-line no-param-reassign
      ref.defaultMuted = true;
      // eslint-disable-next-line no-param-reassign
      ref.muted = true;
      if ($active) ref.play();
    }
  });
  return (
    <NftVideoInner ref={ videoRef } controls={ $active } poster={ poster } { ...props }>
      <source src={ src } />
    </NftVideoInner>
  );
};

export default NftVideo;
