/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { RiCheckLine, RiFileCopyLine, RiShareFill, RiTwitterLine, RiWhatsappLine } from 'react-icons/ri';
import styled from 'styled-components';
import ExternalLink from '~/components/ExternalLink';
import useCopyToClipboard from '~/hooks/useCopyToClipboard';
import { colors } from '~/styles/themes';
import ButtonSquare from './ButtonSquare';
import Dropdown, { DropdownItem } from './Dropdown';

const ActionsButtonContainer = styled.div`
  position: relative;
`;

const ShareButtonIcon = styled.span`
  margin-right: 7px;
  transform: translateY(3px);
`;

const ShareButton: React.FC<{
  url: string;
  text?: string;
  className?: string;
}> = ({
  url,
  text,
  className,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { justCopied, handleCopy } = useCopyToClipboard();
  const buttonRef = React.useRef<HTMLButtonElement>();
  return (
    <ActionsButtonContainer className={ className }>
      <ButtonSquare ref={ buttonRef } onClick={ () => setOpen((o) => !o) }>
        <RiShareFill />
      </ButtonSquare>
      <Dropdown isOpen={ open } handleClose={ () => setOpen(false) } buttonRef={ buttonRef }>
        <DropdownItem as={ ExternalLink } href={ `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}` }>
          <ShareButtonIcon as={ RiTwitterLine } color={ colors.twitter } />
          <span>Share on Twitter</span>
        </DropdownItem>
        <DropdownItem as={ ExternalLink } href={ `https://api.whatsapp.com/send/?text=${encodeURIComponent(text)}` }>
          <ShareButtonIcon as={ RiWhatsappLine } color={ colors.whatsapp } />
          <span>Share on Whatsapp</span>
        </DropdownItem>
        <DropdownItem onClick={ () => handleCopy(url) }>
          <ShareButtonIcon
            as={ justCopied ? RiCheckLine : RiFileCopyLine }
            color={ justCopied ? colors.success : null }
            style={ { transform: 'translateY(0)' } }
          />
          <span>Copy link</span>
        </DropdownItem>
      </Dropdown>
    </ActionsButtonContainer>
  );
};

export default ShareButton;
