import styled from 'styled-components';
import React from 'react';

const TextButton = styled.a`
  font-weight: bold;
  display: contents;
`;

const TextLimitable: React.FC<{
  text: string;
  limit?: number;
}> = ({
  text = '',
  limit = 140,
}) => {
  const [isLimited, setIsLimited] = React.useState<boolean>(true);
  const textLimited = isLimited ? text.slice(0, limit) : text;
  const canBeLimited = text.length > limit;
  const canShowMore = text.length > textLimited.length;
  return (
    <>
      {textLimited}
      {canShowMore ? '... ' : ' '}
      {
        canShowMore
          ? (<TextButton onClick={ () => setIsLimited(false) }>[show more]</TextButton>)
          : canBeLimited
            ? (<TextButton onClick={ () => setIsLimited(true) }>[show less]</TextButton>)
            : null
      }
    </>
  );
};

export default TextLimitable;
