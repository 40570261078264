import copyToClipboard from 'copy-to-clipboard';
import React from 'react';

const useCopyToClipboard = () => {
  const [justCopied, setJustCopied] = React.useState<boolean>(false);
  const handleCopy = (address) => {
    copyToClipboard(address);
    setJustCopied(true);
  };
  React.useEffect(() => {
    if (justCopied) {
      setTimeout(() => {
        setJustCopied(false);
      }, 1000);
    }
  }, [justCopied]);
  return { justCopied, handleCopy };
};

export default useCopyToClipboard;
