/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import styled from 'styled-components';
import ExternalLink from '~/components/ExternalLink';
import ButtonSquare from './ButtonSquare';
import Dropdown, { DropdownIcon, DropdownItem } from './Dropdown';
import Emoji from './Emoji';

const ActionsButtonContainer = styled.div`
  position: relative;
`;

const StyledDropdown = styled(Dropdown)`
  bottom: 1px;
`;

const UserActionsButton: React.FC<{
  address: string;
}> = ({ address }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const buttonRef = React.useRef<HTMLButtonElement>();
  return (
    <ActionsButtonContainer>
      <ButtonSquare ref={ buttonRef } onClick={ () => setOpen((o) => !o) }>
        <BiDotsHorizontalRounded />
      </ButtonSquare>
      <StyledDropdown
        isOpen={ open }
        handleClose={ () => setOpen(false) }
        buttonRef={ buttonRef }
      >
        <DropdownItem onClick={ () => window.alert('Coming soon...') }>
          <DropdownIcon>
            <Emoji v="✈️" style={ { transform: 'translateY(2px)' } } />
          </DropdownIcon>
          <span>Send objkt</span>
        </DropdownItem>
        <DropdownItem
          as={ ExternalLink }
          // eslint-disable-next-line max-len
          href={ `https://docs.google.com/forms/d/e/1FAIpQLSf8jaKpqukdLarsm8Ai1xKf9H1nlJQZopbTc5UPXWrvkJtoBA/viewform?usp=pp_url&entry.73971236=${address}` }
        >
          <DropdownIcon>
            <Emoji v="🚨" style={ { transform: 'translateY(3px)' } } />
          </DropdownIcon>
          <span>Report copyminter</span>
        </DropdownItem>
      </StyledDropdown>
    </ActionsButtonContainer>
  );
};

export default UserActionsButton;
