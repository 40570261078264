import styled from 'styled-components';
import React from 'react';
import { RiDiscordLine, RiTwitterLine, RiTimeLine, RiGithubLine } from 'react-icons/ri';
import { getUserLink, getUserLinkLabel } from '~/graphql';
import useTzProfile from '~/hooks/useTzProfile';
import { media } from '~/styles/media';
import FlexRow from '~/components/FlexRow';
import { useActiveSince } from '~/hooks/useFirstMint';
import { colors } from '~/styles/themes';
import ExternalLink from './ExternalLink';
import { User } from '~/types';

const LinkIcon = styled.div`
  margin-right: 5px;
  position: relative;
  top: 3px;
`;

const LinksRow = styled(FlexRow)`
  align-items: flex-start;
  margin: 0 -8px;
  ${media.mobile`
    display: block;
  `}
`;

const LinkContainer = styled(FlexRow)`
  align-items: flex-start;
  margin: 0 8px;
  display: inline-flex;
  ${media.mobile`
    display: flex;
  `}  
`;

const UserLinks: React.FC<{
  user: User;
  className?: string;
}> = ({
  user,
  className,
}) => {
  const { data: tzProfile = {} } = useTzProfile(user.address);
  const henLink = `https://hen-mirror.hic.af${getUserLink(user)}`;
  const { activeSince, status } = useActiveSince(user.address);
  const isLoadingActiveSince = status === 'loading';
  return (
    <LinksRow className={ className }>
      {
        getUserLink(user) ? (
          <LinkContainer>
            <span style={ { marginRight: 3, transform: 'translateY(1px)' } }>〓</span>
            <a href={ henLink } target="_blank" rel="noreferrer">
              { getUserLinkLabel(user).substring(1) }
            </a>
          </LinkContainer>
        ) : null
      }
      {
        tzProfile?.twitter ? (
          <LinkContainer>
            <LinkIcon as={ RiTwitterLine } color={ colors.twitter } />
            <ExternalLink href={ `https://twitter.com/${tzProfile.twitter}` }>
              { tzProfile.twitter }
            </ExternalLink>
          </LinkContainer>
        ) : null
      }
      {
        tzProfile?.github ? (
          <LinkContainer style={ { marginRight: 0 } }>
            <LinkIcon as={ RiGithubLine } size={ 15 } />
            <ExternalLink href={ `https://github.com/${tzProfile.github}` }>
              { tzProfile.github }
            </ExternalLink>
          </LinkContainer>
        ) : null
      }
      {
        tzProfile?.discord ? (
          <LinkContainer style={ { marginRight: 0 } }>
            <LinkIcon as={ RiDiscordLine } color={ colors.discord } size={ 15 } />
            { tzProfile.discord }
          </LinkContainer>
        ) : null
      }
      {
        isLoadingActiveSince || activeSince ? (
          <LinkContainer>
            <LinkIcon as={ RiTimeLine } />
            {
              isLoadingActiveSince ? (
                <span>-</span>
              ) : (
                <span>{ `Started minting ${activeSince}` }</span>
              )
            }
          </LinkContainer>
        ) : null
      }
    </LinksRow>
  );
};

export default UserLinks;
